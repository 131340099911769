<template>
 <Swipe class="my-swipe" :autoplay="3000" indicator-color="white">
    <SwipeItem class="van-swipe-item"  v-for="image in strToArray(product.imageKeys)" :key="image"><img :src="config.url+'/admin/file/index/'+image" /></SwipeItem> 
  </Swipe>
 
  <div class="section">
    <div class="price">￥{{product.originalPrice}}</div>
    <div>{{product.name}}</div> 
  </div>
  <Row class="section"><Col class="title" span="3">运费</Col><Col class="des" span="16">包邮</Col>  </Row> 
  
   <div class="section content" v-html="product.fullDescription">
    
   </div>
  <ActionBar style="max-width:800px">
    <!--<ActionBarIcon  icon="chat-o" text="客服"></ActionBarIcon>-->
    <ActionBarButton type="danger" text="付款方式" @click="gotoBuy"></ActionBarButton>
  </ActionBar> 
</template>


<script>
// import HelloWorld from './components/HelloWorld.vue'
// import { createApp } from "vue";
import { Swipe, SwipeItem,Row ,Col,ActionBar,ActionBarButton} from "vant";
import axios from "axios";
import config from "../components/config.js";
import routers from '../routes' 

export default {
  name: "Detail",
  props:['id'],
  components:{
    Swipe,SwipeItem ,Row,Col,ActionBar,ActionBarButton
  },
  data() {
     return {
         product:{},
         config:config
     }
  },
  methods:{
      init(){
          console.log(routers);
        axios
            .get(config.url + '/client/product/detail/08dad844-ccee-4700-8bb1-dda4f1d36a86' )
        .then(response => {console.log( response);this.product=response.data.data;})
        .catch(function (error) { // 请求失败处理
            console.log(error);
        });
      //   this.getWxTicket();//获取微信的Ticker
      //   wx.ready(function () {
      //   console.log('wx ready=>', data.title);
      //   isWxReady = true;
      //   if (data.title) {
      //     updateShareConfig();
      //   }
      // });
      },
      getWxTicket(){
    //     axios
    //         .get(config.url + '/wx/getticket?url='+encodeURIComponent(document.location.href) )
    //     .then(res =>{
    //       if (res) {
    //     const { NonceStr, Signature, Timestamp } = res;
    //     // wx.config({
    //     //   debug: false,
    //     //   appId: config.appId(),
    //     //   timestamp: Timestamp,
    //     //   nonceStr: NonceStr,
    //     //   signature: Signature,
    //     //   jsApiList: ["updateAppMessageShareData", "updateTimelineShareData", "onMenuShareTimeline", "onMenuShareAppMessage"] // 必填，需要使用的 JS 接口列表
    //     // });
    //   }
    // });
      },
      strToArray(imgs){
        if(!imgs)return [];
        return imgs.split(",");
      },
      gotoBuy(){
        routers.push({
            path: '/buyinfo/' + routers.currentRoute.value.params.id
        })
      }
  },
  mounted(){
      this.init();
  }
};
</script>



<style> 

.my-swipe {
    overflow: hidden;
}
.my-swipe .van-swipe-item {
  color: #fff; 
  text-align: center; 
  width:100%;
  max-width:800px;
}
.van-swipe-item img{
    width:100%;
}
/* 每一个分区 */
.section{
    margin-bottom:10px;
    background-color:white;
    text-align: left;
    font-size:1em;
    line-height:3em;
    padding:0px 0.5em;
}

.section .price{
    font-size:2em;
    font-weight:bold;
    color:red;
    line-height:1.5em;
}
 .section .title{
     color:#999;
 }
.section.content{
   line-height:1.5em;
   text-align:justify;
   padding:0.5em;
   margin-bottom:30px;
}
.section.content  p{
    text-indent: 2em;
}
.section.content img{
    width:100%;
     
}
.section.content figure.image{
  width:100%;
  margin:0px;
}
  
</style>
