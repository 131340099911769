
import { createRouter, createWebHistory } from 'vue-router'
import Detail from './pages/Detail'
import App from './pages/App'
import BuyInfo from './pages/BuyInfo'


const router = createRouter({
    history: createWebHistory(),
    routes: [
      {
        path: '/',
        component: App,
        meta: {
            title:"首页"
        }
      },
      {
        path: '/product/detail/:id',
        component: Detail,
        meta: {
            title:"昭君密橙纽荷尔"
        }
      },
      {
        path: '/buyinfo/:id',
        component: BuyInfo,
        meta: {
            title:"昭君密橙纽荷尔"
        }
      }
    ]
  })


export default router
