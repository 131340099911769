// import Detail from './pages/Detail.vue' 
import App from './pages/App'
import { createApp } from 'vue'
//import page from 'page'
import routers from './routes'

routers.beforeEach((to,from,next) => {
    if (to.meta.title) {
        document.title = to.meta.title;
    }
    next();
});
createApp(App).use(routers).mount('#app')

// app.prototype.$axios=axios;
// axios.defaults.baseURL="/api";
// axios.defaults.headers.post['Content-Type']="application/json";
