<template>
  <div class="section">
    <div>{{ product.name }}支付信息表</div>
  </div> 
    <div class="section" style=" text-align:center;margin-bottom:-5px;">
      <div style="font-weight:bold;">民革湖北省企联会秘书处指定本次活动汇款账户</div>
    </div>
    <div class="section" style="text-indent:2em;margin-bottom:-5px;">
      <div>请民革党员同志们根据登记报备的认购数量打款，并做好备注。</div>
    </div>
    <ul class="bank-detail">
      <li>
        <span class="">
          银行:
        </span>
        <div>
          中国建设银行武汉洪山支行
        </div>
      </li>
      <li>
        <span>
          账号:
        </span>
        <div>
          6217002870069240792
        </div>
      </li>
      <li>
        <span>
          账户名:
        </span>
        <div>
          李涛
        </div>
      </li>
      <li>
        <b
          style="color:#5B9AD4;font-weight: normal; padding:0 1em;margin-bottom:10px"
          >备注 : 非本人账户打款时请备注本人姓名</b
        >
      </li>   
    </ul>
</template>

<script>
// import { CellGroup, Field, Button } from "vant";
import axios from "axios";
import config from "../components/config.js";
import routers from "../routes";

export default {
  name: "Detail",
  props: ["id"],
  components: {
    // CellGroup,
    // Field,
    // Button,
  },
  data() {
    return {
      product: {},
      buyinfo: {num:1},
    };
  },
  methods: {
    init() {
      axios
        .get(
          config.url +
            "/app/product/detail/" +
            routers.currentRoute.value.params.id
        )
        .then((response) => {
          this.product = response.data.data;
          this.buyinfo.productid = this.product.id;
        })
        .catch(function(error) {
          // 请求失败处理
          console.log(error);
        });
    },
    submit() {
      var error = [];
      if (!this.buyinfo.userName || this.buyinfo.userName == "") {
        error.push("用户名不能为空");
      }
      if (!this.buyinfo.phone || this.buyinfo.phone == "") {
        error.push("电话号码不能为空");
      }
      if (!this.buyinfo.org || this.buyinfo.org == "") {
        error.push("组织不能为空");
      }
      if (!this.buyinfo.address || this.buyinfo.address == "") {
        error.push("地址不能为空");
      }
      if (error.length > 0) {
        alert(error.join("，") + "。");
        return;
      }
      axios
        .post(config.url + "/app/buyinfo/add", this.buyinfo)
        .then((response) => {
          if (response.data.isSuccess) {
            this.$router.back();
          }
        })
        .catch(function(error) {
          // 请求失败处理
          console.log(error);
        });
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style>
.bank-detail{
  background-color:white;
  padding:5px 0px;
}
.bank-detail span {
  padding-left: 1em;
  width: 80px;
  text-align: left;
}
.bank-detail div {
  text-align: left;
}
.bank-detail li {
  font-size: 14px;
  line-height: 2.2em;
  display: flex;
}
.bank-line {
  height: 10px;
  background-color: #eee;
}
.myarea {
  line-height: 1.5em;
  padding: 0.5em;
  width: 100%;
  border: 1px solid #eee;
}
.section {
  margin-bottom: 10px;
  background-color: white;
  font-size: 1em;
  line-height: 3em;
  padding: 0px 0.5em;
}
</style>
