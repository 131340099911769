<template>  
<div>
    <router-view /> 
</div>
</template>

<script>
// import HelloWorld from './components/HelloWorld.vue'
// import { createApp } from "vue"; 
export default {
  name: "App",
  components:{
    
  } 
};
</script> 